@import "src/_app/styles/mixins.scss";
.qr_menu {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
  border-radius: 16px;
  color: var(--primary);
  background: var(--aurora_select_default);

  h4 {
    margin: 0;

    @include tSubtitle_Semibold;
  }

  &__qr {
    position: relative;
    padding: 24px;
    border: 1px solid var(--border_header);
    border-radius: 16px;

    .qr__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: var(--bg_modal_header);
      padding: 8px;
      border-radius: 10px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    @include respond(tablet) {
      padding: 16px;
    }
  }

  @include respond(tablet) {
    padding: 6px 12px;
  }
}

body {
  // Main block colors
  --mainPrimary: #000;
  --mainSecondary: #fff;
  --footerGray: #c5c5c5;
  --mainGray: #888;
  --tertiary: #a3a3a3;
  --mainBackgroundSecondary: #f6f6f6;
  --white: #fff;
  --black: #000;

  // Background colors
  --contentTertiary: #c4c4c4;
  --dropdown_bg: #fff;
  --bg_1: #000;
  --bg_1_reverse: #141414;
  --bg_2: #f6f6f6;
  --bg_3: #f6f6f6;
  --bg_4: #000;
  --bg_5: #fff;
  --bg_6: #fff;
  --bg_7: #ebebeb;
  --black_gradient_1: linear-gradient(85.17deg, #000 -0.5%, #2e2e2e 98.72%);
  --black_gradient_2: linear-gradient(
    180deg,
    #353535 0%,
    rgb(47 47 47 / 0%) 100%
  );
  --black_gradient_3: radial-gradient(
    circle,
    rgb(0 0 0 / 1%) 0%,
    rgb(72 72 72 / 46%) 50%,
    rgb(0 0 0 / 100%) 100%
  );
  --black_gradient_4: linear-gradient(
    231.15deg,
    rgb(246 246 246 / 8%) 6.02%,
    #f6f6f6 97.45%
  );
  --black_gradient_5: #f6f6f6;
  --black_gradient_6: #fff;
  --border_gradient_dark: transparent;
  --bg_8: linear-gradient(
    231.15deg,
    rgb(246 246 246 / 8%) 6.02%,
    #f6f6f6 97.45%
  );
  --bg_9: #ebebeb;
  --bg_10: #fff;
  --bg_card: transparent;
  --bg_advantages: transparent;
  --bg_staking: rgb(0 0 0 / 8%);
  --bg_11: #ebebeb;
  --bg_12: #f8f8f8;
  --bg_13: #f6f6f6;
  --bg_14: linear-gradient(247.95deg, #ededed 5.87%, #f6f6f6 96.55%);
  --bg_15: #f6f6f6;
  --bg_16: #fff;
  --bg_17: #f6f6f6;
  --bg_18: #fff;
  --bg_19: #fff;
  --bg_20: linear-gradient(197.8deg, rgb(255 255 255) -33.83%, #ebebeb 83.83%);
  --bg_21: #fff;
  --bg_22: #f6f6f6;
  --bg_23: linear-gradient(
    200.8deg,
    rgb(248 248 248 / 66%) 11.17%,
    #ebebeb 89.83%
  );
  --bg_24: #f6f6f6;
  --bg_25: #ebebeb;
  --bg_26: #fff;
  --bg_27: #f5f5f5;
  --bg_28: rgb(246 246 246 / 100%);
  --bg_29: #fff;
  --bg_30: #fff;
  --bg_31: #fff;
  --bg_32: rgb(0 0 0 / 8%);
  --bg_33: #fff;
  --bg_34: #f5f5f5;
  --bg_35: rgb(246 246 246 / 100%);
  --bg_36: #fff;
  --bg_37: #ffffff2e;
  --bg_38: #fafafa;
  --bg_bubbleA: #f8f8f8a8;
  --bg_bubbleB: #ebebeb;
  --bg_moonA: #fff;
  --bg_moonB: #f6f6f6;
  --bg_40: #000;
  --bg_41: #f6f6f6;
  --bg_42: #fff;
  --bg_43: #fff;
  --bg_44: #fff;
  --bg_45: linear-gradient(270deg, rgb(246 246 246 / 0%) 0%, #f6f6f6 100%);
  --bg_46: rgb(255 255 255 / 14%);
  --bg_47: linear-gradient(248deg, #ededed 5.87%, #f6f6f6 96.55%);
  --bg_48: #fff;
  --bg_49: linear-gradient(206deg, #ececec 19.25%, #f6f6f6 87.33%);
  --bg_50: #e6e6e6;
  --bg_banner: linear-gradient(
    180deg,
    rgb(0 0 0 / 85%) 0%,
    rgb(0 0 0 / 0%) 50%,
    rgb(0 0 0 / 99%) 100%
  );
  --bg_banner_gateway_CPT: #fff;
  --bg_banner_gateway_accept: none;
  --bg_widgets: rgb(255 255 255 / 40%);
  --bg_widgets_divider: hsl(0deg 0% 92%);
  --bg_widgets_income: rgb(255 255 255 / 80%);
  --bg_widgets_chat: #fff;
  --borderAlpha_1: rgb(255 255 255 / 30%);
  --boxShadow: rgb(0 0 0 / 70%);
  --backgroundAlpha_2: rgb(0 0 0 / 4%);
  --backgroundAlpha_3: rgb(0 0 0 / 8%);
  --backgroundLandDefault: #fff;
  --backgroundLandHover: #ebebeb;
  --backgroundLandActive: #e6e6e6;
  --bgHeaderHover: #ebebeb;
  --bgHeaderActive: #e6e6e6;
  --bgHeaderItemHover: #f6f6f6;
  --select_item_hover: #fff;
  --bg_modal: linear-gradient(
    200deg,
    rgb(248 248 248 / 66%) 11%,
    rgb(235 235 235 / 100%) 90%
  );
  --bg_cookieModal: #fff;
  --bg_modal_header: #fff;
  --bg_buttonUp: #f6f6f6;
  --bg_payForm_banner: linear-gradient(90deg, #ccc 0%, #f2f2f2 100%);
  --bg_icon_hover: #e6e6e6;
  --bg_icon_active: #e3e3e3;
  --bg_btn_disabled: rgb(0 0 0 / 36%);
  --bg_condition_line: rgb(0 0 0 / 10%);
  --bg_staking_card_dark: #141414;
  --bg_whitelabel_card_icon: #141414;
  --demo_dashboard_1: rgb(29 29 29 / 100%);
  --demo_dashboard_2: rgb(0 0 0 / 100%);
  --demo_dashboard_3: rgb(246 246 246 / 100%);
  --bg_mercuryo_banner: #fff;
  --bg_mass_block: #f6f6f6;
  --bg_mass_grad: rgb(246 246 246 / 0%);
  --bg_convert_difference: #fff;
  --bg_catalog_select: rgb(255 255 255 / 100%);
  --bg_neutral_10: #fff;
  --bg_neutral_20: #f6f6f6;
  --bg_neutral_30: #dfdfdf;
  --bg_neutral_80: #0a0a0a;
  --block_bg_cards: #fff;
  --neutral_40: #d4d4d4;
  --aurora_modal_bg: #0a0a0a8a;
  --aurora_select_default: #fff;
  --aurora_select_hover: #f6f6f6;
  --aurora_select_item_hover: #fff;

  // Text colors
  --primary: #000;
  --secondary: #fff;
  --secondary_header: #666;
  --primary_header: #0a0a0a;
  --text_1: #666;
  --text_2: #146636;
  --text_3: rgb(0 0 0 / 40%);
  --text_4: #666;
  --text_5: rgb(116 116 116 / 100%);
  --text_6: #747474;
  --toggleText: #a3a3a3;
  --coinName: #747474;
  --bannerText: #4a4a4a;
  --text_icon_primary: #0a0a0a;
  --text_icon_inverted: #fff;
  --text_disable: #808080;

  // Shadow colors
  --shadow_1: rgb(0 0 0 / 26%);
  --shadow_2: rgb(0 0 0 / 4%);
  --shadow_3: 0px 0px 80px rgb(0 0 0 / 10%);
  --shadow_4: 0px 0px 6px 0px rgb(0 0 0 / 4%), 0px 8px 16px 0px rgb(0 0 0 / 4%);

  // Border colors
  --border_1: #e8e8e8;
  --border_2: #fff;
  --border_2_inverted: #000;
  --border_3: rgb(0 0 0 / 10%);
  --border_4: #cdcdcd;
  --border_5: rgb(255 255 255 / 32%);
  --border_6: rgb(0 0 0 / 10%);
  --border_7: #fff;
  --border_8: rgb(0 0 0 / 10%);
  --border_9: #ebebeb;
  --border_10: rgb(0 0 0 / 10%);
  --border_11: rgb(0 0 0 / 10%);
  --border_12: #e6e6e6;
  --border_15: transparent;
  --border_14: #ebebeb;
  --border_13: #e6e6e6;
  --border_header: #e6e6e6;
  --border_dark: transparent;
  --border__processing_advantages: transparent;
  --border_lang: transparent;
  --border_blog-card: transparent;
  --divider_crms: rgb(0 0 0 / 20%);
  --border_16: #ddd;
  --border_radio: rgb(0 0 0 / 12%);
  --border_swiper_button: rgb(0 0 0 / 16%);

  // Processing
  --border_processing_item: rgb(0 0 0 / 10%);
  --bg_item: #f6f6f6;
  --bg_block: #f6f6f6;
  --line_color: #e6e6e6;
  --bg_wrapper: #fff;
  --border_item: #444;
  --central_bg: linear-gradient(85deg, #000 -0.5%, #2e2e2e 98.72%);
  --central_content_bg: linear-gradient(255deg, #282828 3.88%, #232323 100%);
  --central_content_border: #292929;
  --bg_blocks: linear-gradient(85deg, #000 -0.5%, #2e2e2e 98.72%);
  --catalog_bg_2: #f6f6f6;

  // Button colors
  --btnPrimaryHover: rgb(0 0 0 / 60%);
  --btnPrimaryActive: rgb(0 0 0 / 70%);
  --btnPrimaryReverseHover: rgb(255 255 255 / 80%);
  --btnPrimaryReverseActive: rgb(255 255 255 / 90%);
  --btnSecondaryHover: rgb(0 0 0 / 3%);
  --btnSecondaryActive: rgb(0 0 0 / 4%);
  --btnSecondaryReverseHover: rgb(0 0 0 / 30%);
  --btnSecondaryReverseActive: rgb(0 0 0 / 40%);
  --btnRoundedBorder: rgb(0 0 0 / 16%);
  --blogCommentBorder: none;
  --blogRateBorder: rgb(0 0 0 / 10%);

  // Foundation
  --negative: #ff3d4d;
  --warning: #ffa64d;
  --success: #3ac270;
  --backgroundAlpha_Negative_1: rgb(255 61 77 / 6%);
  --backgroundAlpha_Negative_2: rgb(255 61 77 / 16%);
  --backgroundAlpha_Success_1: rgb(58 194 112 / 6%);
  --backgroundAlpha_Success_2: rgb(58 194 112 / 16%);
  --backgroundAlpha_Warning_1: rgb(255 166 77 / 6%);
  --backgroundAlpha_Warning_2: rgb(255 166 77 / 16%);
  --gateway_banner_background_url: url('/img/common/main-gateway-banner.png');
  --staking_calc_background_url: url('/img/staking/calc-background-white.jpg');
  --staking_calc_background_laptop_url: url('/img/staking/calc-background-white-laptop.jpg');
  --processing_banner_background_url: url('/img/processing/processing-banner-back-light.png');
  --processing_choose_background_url: url('/img/processing/processing-chose-background.png');
  --convert_better_background_url: url('/img/convert/convert-better-bg.png');
  --app_banner_background_url: url('/img/banners/cryptomus-app-banner/app-banner-background-light.png');
  --app_banner_background_url_tablet: url('/img/banners/cryptomus-app-banner/app-banner-background-light-tablet.png');
  --app_banner_background_url_mobile: url('/img/banners/cryptomus-app-banner/app-banner-background-light-mobile.png');
  --cmrs_minted_background_url: url('/img/banners/crms-minted-banner/crms-minted-banner-background.png');
  --cmrs_minted_background_tablet: url('/img/banners/crms-minted-banner/crms-minted-banner-background-tablet.png');
  --cmrs_minted_background_mobile: url('/img/banners/crms-minted-banner/crms-minted-banner-background-mobile.png');
  --cmrs_minted_gradient: none;
  --cmrs_minted_gradient_mobile: none;
  --cmrs_banner_image_margin: 290px;
  --cmrs_banner_image_margin_tablet: 110px;
  --cmrs_banner_image_size_mobile: 675px 500px;

  // TABLE
  --table_bg: #fff;
  --table_action_btn: #fff;
  --table_action_btn_hover: #0c0c0c;

  // TABLE [END]

  --footer_btn: #fff;
  --select_item: #efeeee;

  // DARK MODE DARK MODE DARK MODE DARK MODE
  &.dark {
    // Background colors
    --contentTertiary: #888;
    --dropdown_bg: #2c2c2c;
    --bg_1: #141414;
    --bg_1_reverse: #000;
    --bg_2: #242424;
    --bg_3: #141414;
    --bg_4: rgb(255 255 255 / 14%);
    --bg_5: #242424;
    --bg_6: #141414;
    --bg_7: #141414;
    --black_gradient_1: linear-gradient(
      13.97deg,
      #141414 6.27%,
      #272727 104.76%
    );
    --black_gradient_2: linear-gradient(
      180deg,
      #353535 0%,
      rgb(47 47 47 / 0%) 100%
    );
    --black_gradient_3: radial-gradient(
      circle,
      rgb(0 0 0 / 1%) 0%,
      rgb(72 72 72 / 46%) 50%,
      rgb(0 0 0 / 100%) 100%
    );
    --black_gradient_5: linear-gradient(to bottom, #2f2f2f 38%, #1d1d1d 100%);
    --black_gradient_6: linear-gradient(
      13.97deg,
      #141414 6.27%,
      #272727 104.76%
    );
    --border_gradient_dark: linear-gradient(
      64.5deg,
      #141414 0.46%,
      #272727 96.28%
    );
    --bg_8: linear-gradient(85.17deg, #000 -0.5%, #2e2e2e 98.72%);
    --bg_9: #141414;
    --bg_10: #1d1d1d;
    --bg_card: linear-gradient(180deg, #353535, rgb(47 47 47 / 0%)) border-box;
    --bg_advantages: linear-gradient(
        180deg,
        rgb(39 39 39 / 100%),
        rgb(20 20 20 / 100%)
      )
      border-box;
    --bg_staking: rgb(255 255 255 / 18%);
    --bg_11: #1d1d1d;
    --bg_12: #1d1d1d;
    --bg_13: linear-gradient(262.6deg, #1f1f1f 2.76%, #141414 97.54%);
    --bg_14: linear-gradient(255.05deg, #282828 3.88%, #232323 100%);
    --bg_15: #000;
    --bg_16: linear-gradient(
      235.35deg,
      rgb(47 47 47 / 38%) 3.89%,
      #1d1d1d 94.43%
    );
    --bg_17: #373737;
    --bg_18: #000;
    --bg_19: #0f0f0f;
    --bg_20: #141414;
    --bg_21: #292929;
    --bg_22: #1d1d1d;
    --bg_23: #0f0f0f;
    --bg_24: #141414;
    --bg_25: #141414;
    --bg_26: #000;
    --bg_27: #292929;
    --bg_28: rgb(36 36 36 / 100%);
    --bg_29: #1d1d1d;
    --bg_30: #282828;
    --bg_31: linear-gradient(180deg, #222 0%, #141414 100%);
    --bg_32: #ffffff2e;
    --bg_33: #000;
    --bg_34: #292929;
    --bg_35: rgb(36 36 36 / 100%);
    --bg_36: #1d1d1d;
    --bg_37: #00000014;
    --bg_38: #242424;
    --bg_39: rgb(36 36 36 / 100%);
    --bg_bubbleA: #222;
    --bg_bubbleB: #141414;
    --bg_moonA: #222;
    --bg_moonB: #141414;
    --bg_40: #292929;
    --bg_41: #0f0f0f;
    --bg_42: #1d1d1d;
    --bg_43: #2d2d2d;
    --bg_44: linear-gradient(226deg, #282828 0%, #232323 100%);
    --bg_45: linear-gradient(270deg, rgb(15 15 15 / 0%) 0%, #0f0f0f 100%);
    --bg_46: #242424;
    --bg_47: linear-gradient(255deg, #282828 3.88%, #232323 100%);
    --bg_48: #303030;
    --bg_49: linear-gradient(206deg, #000 19.25%, #0c0c0c 87.33%);
    --bg_50: #2d2d2d;
    --bg_banner: linear-gradient(
      180deg,
      #141414 0%,
      rgb(0 0 0 / 0%) 50%,
      #141414 100%
    );
    --bg_modal: #1d1d1d;
    --bg_modal_header: #202020;
    --bg_btn_disabled: rgb(255 255 255 / 36%);
    --bg_condition_line: #2d2d2d;
    --demo_dashboard_1: rgb(79 79 79 / 96%);
    --demo_dashboard_2: rgb(79 79 79 / 67%);
    --demo_dashboard_3: rgb(79 79 79 / 31%);
    --bg_banner_gateway_CPT: linear-gradient(
      262.6deg,
      #1f1f1f 2.76%,
      #141414 97.54%
    );
    --bg_banner_gateway_accept: linear-gradient(
      90deg,
      #141414 0%,
      rgb(20 20 20 / 0%) 100%
    );
    --bg_widgets: rgb(61 61 61 / 40%);
    --bg_widgets_divider: #373737;
    --bg_widgets_income: rgb(57 57 57 / 80%);
    --bg_widgets_chat: rgb(70 70 70 / 80%);
    --bg_cookieModal: #292929;
    --bg_buttonUp: #292929;
    --bg_payForm_banner: linear-gradient(90deg, #141414 0%, #1f1f1f 100%);
    --bg_icon_hover: #303030;
    --bg_icon_active: #373737;
    --bg_whitelabel_card_icon: #242424;
    --borderAlpha_1: rgb(0 0 0 / 10%);
    --boxShadow: rgb(44 44 44 / 70%);
    --backgroundAlpha_2: rgb(255 255 255 / 16%);
    --backgroundAlpha_3: rgb(255 255 255 / 18%);
    --backgroundLandDefault: #2d2d2d;
    --backgroundLandHover: #333;
    --backgroundLandActive: #393939;
    --bgHeaderHover: #151515;
    --bgHeaderActive: #191919;
    --bgHeaderItemHover: #333;
    --bg_mercuryo_banner: #0c0c0c;
    --bg_mass_block: #0c0c0c;
    --bg_mass_grad: rgb(15 15 15 / 0%);
    --catalog_bg_2: #0c0c0c;
    --bg_convert_difference: linear-gradient(180deg, #000 0%, #101010 100%);
    --bg_catalog_select: rgb(48 48 48 / 100%);
    --bg_51: #0c0c0c;
    --bg_neutral_10: #0a0a0a;
    --bg_neutral_20: #1a1a1a;
    --bg_neutral_30: #343434;
    --bg_neutral_80: #fff;
    --block_bg_cards: #252525;
    --neutral_40: #525252;
    --aurora_modal_bg: #0a0a0a8a;
    --aurora_select_default: #2c2c2c;
    --aurora_select_hover: #333;
    --aurora_select_item_hover: #424242;

    // Text colors
    --primary: #fff;
    --secondary: #000;
    --secondary_header: #b1b1b1;
    --primary_header: #fff;
    --text_1: #c5c5c5;
    --text_2: #3ac270;
    --text_3: rgb(255 255 255 / 50%);
    --text_4: #c5c5c5;
    --text_5: rgb(197 197 197 / 100%);
    --toggleText: #888;
    --coinName: #c5c5c5;
    --bannerText: #a0a0a0;
    --text_icon_primary: #fff;
    --text_icon_inverted: #0a0a0a;
    --select_item_hover: #424242;
    --text_disable: #828282;

    // Foundation
    --negative: #ff3d4d;
    --warning: #ffa64d;
    --success: #3ac270;

    // Shadow colors
    --shadow_2: rgb(255 255 255 / 15%);
    --shadow_3: 0px 8px 16px rgb(0 0 0 / 4%), 0px 0px 6px rgb(0 0 0 / 4%);
    --shadow_4:
      0px 0px 12px 0px rgb(37 33 41 / 4%), 0px 16px 32px 0px rgb(37 33 41 / 8%);

    // Border colors
    --border_1: #444;
    --border_2: #000;
    --border_2_inverted: #fff;
    --border_3: rgb(255 255 255 / 10%);
    --border_4: rgb(46 46 46);
    --border_5: rgb(0 0 0 / 16%);
    --border_6: #373737;
    --border_7:
      radial-gradient(
          circle at 100% 100%,
          #1d1d1d 0,
          #1d1d1d 23px,
          transparent 23px
        )
        0% 0% / 24px 24px no-repeat,
      radial-gradient(
          circle at 0 100%,
          #1d1d1d 0,
          #1d1d1d 23px,
          transparent 23px
        )
        100% 0% / 24px 24px no-repeat,
      radial-gradient(
          circle at 100% 0,
          #1d1d1d 0,
          #1d1d1d 23px,
          transparent 23px
        )
        0% 100% / 24px 24px no-repeat,
      radial-gradient(circle at 0 0, #1d1d1d 0, #1d1d1d 23px, transparent 23px)
        100% 100% / 24px 24px no-repeat,
      linear-gradient(#1d1d1d, #1d1d1d) 50% 50% / calc(100% - 2px)
        calc(100% - 48px) no-repeat,
      linear-gradient(#1d1d1d, #1d1d1d) 50% 50% / calc(100% - 48px)
        calc(100% - 2px) no-repeat,
      linear-gradient(0deg, transparent 0%, #353535 50%);
    --border_8: #444;
    --border_9: #373737;

    // --border_9: rgba(255, 255, 255, 0.30);
    --border_10: #242424;
    --border_12: #c5c5c5;
    --border_14: #2d2d2d;
    --border_13: #2d2d2d;
    --border_header: #3c3c3c;
    --border_lang: #ffffff4d;
    --border_15: #141414;
    --border_16: #444;
    --border_blog-card: #2d2d2d;
    --divider_crms: rgb(255 255 255 / 20%);
    --border_radio: rgb(255 255 255 / 12%);
    --border_swiper_button: rgb(255 255 255 / 32%);

    // Processing
    --border_processing_item: #141414;
    --line_color: #2d2d2d;
    --bg_wrapper: #000;
    --bg_item: #0f0f0f;
    --bg_block: #191919;
    --border_item: #141414;
    --central_bg: #0f0f0f;
    --central_content_bg: linear-gradient(180deg, #000 0%, #101010 100%);
    --central_content_border: #141414;
    --bg_blocks: linear-gradient(85deg, #000 -0.5%, #2e2e2e 98.72%);
    --border_dark: #242424;
    --border__processing_advantages: linear-gradient(
      64.5deg,
      #141414 0.46%,
      #272727 96.28%
    );

    // Button colors
    --btnPrimaryHover: rgb(255 255 255 / 80%);
    --btnPrimaryActive: rgb(255 255 255 / 90%);
    --btnPrimaryReverseHover: rgb(0 0 0 / 80%);
    --btnPrimaryReverseActive: rgb(0 0 0 / 90%);
    --btnSecondaryHover: rgb(255 255 255 / 14%);
    --btnSecondaryActive: rgb(255 255 255 / 16%);
    --btnSecondaryReverseHover: rgb(255 255 255 / 30%);
    --btnSecondaryReverseActive: rgb(255 255 255 / 40%);
    --btnRoundedBorder: rgb(255 255 255 / 32%);
    --blogCommentBorder: 1px solid rgb(255 255 255 / 30%);
    --blogRateBorder: rgb(255 255 255 / 30%);
    --gateway_banner_background_url: url('/img/common/main-gateway-banner-dark.png');
    --staking_calc_background_url: url('/img/staking/calc-background-black.jpg');
    --staking_calc_background_laptop_url: url('/img/staking/calc-background-black-laptop.jpg');
    --processing_banner_background_url: url('/img/processing/processing-banner-back.png');
    --processing_choose_background_url: url('/img/processing/processing-chose-background-dark.png');
    --convert_better_background_url: url('/img/convert/convert-better-bg-dark.png');
    --app_banner_background_url: url('/img/banners/cryptomus-app-banner/app-banner-background-dark.png');
    --app_banner_background_url_tablet: url('/img/banners/cryptomus-app-banner/app-banner-background-dark-tablet.png');
    --app_banner_background_url_mobile: url('/img/banners/cryptomus-app-banner/app-banner-background-dark-mobile.png');
    --cmrs_minted_background_url: url('/img/banners/crms-minted-banner/crms-minted-banner-background-dark.png');
    --cmrs_minted_background_tablet: url('/img/banners/crms-minted-banner/crms-minted-banner-background-dark-tablet.png');
    --cmrs_minted_background_mobile: url('/img/banners/crms-minted-banner/crms-minted-banner-background-dark-mobile.png');
    --cmrs_minted_gradient: url('/img/banners/crms-minted-banner/crms-gradient.png');
    --cmrs_minted_gradient_mobile: url('/img/banners/crms-minted-banner/crms-gradient-mobile.png');
    --cmrs_banner_image_margin: 540px;
    --cmrs_banner_image_margin_tablet: 435px;
    --cmrs_banner_image_size_mobile: 835px 460px;

    // TABLE
    --table_bg: #191919;
    --table_action_btn: #383838;
    --table_action_btn_hover: #fff;

    // TABLE [END]

    --footer_btn: #252525;
    --select_item: #383838;
  }
}
